<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .weight {
    font-weight: 700;
  }
  .container {
    max-width: 400px;
    margin: auto;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .Calendar-div {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
  }

  .gou {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  :deep(.el-calendar-day) {
    height: 40px;

    padding: 0 !important;
    // widht: 40px !important;
  }

  :deep(.el-calendar) {
    border-radius: 8px;
  }

  :deep(.el-calendar__body) {
    padding: 5px 30px 15px 30px !important;
  }

  :deep(.el-calendar__header) {
    padding: 10px 20px !important;
  }

  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  .color_expired {
    background-color: #f5f5f5 !important;
    color: #cacaca !important;
  }

  .color_bai {
    background-color: #ffffff !important;
  }

  .color_appt {
    background: #10bd8e;
    color: #000000;
  }
  .borderActive {
    border: 2px solid #ae6ad9fa !important;
    box-sizing: border-box !important;
  }

  .color_appt_none {
    background-color: #8b8b8b;
    color: #000000;
  }

  .color_draf {
    background-color: #bbd2e9;
  }
  .calender-title1 {
    font-size: 13px;
    color: #333;
    font-style: normal;
    text-align: left;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: 400;
    clear: both;
    padding-left: 20px;
  }

  .timeTable {
    border-top: 2px solid black;
    /* 上边框 */
    border-right: 2px solid black;
    /* 右边框 */
    border-left: 2px solid black;
    /* 左边框 */
    border-bottom: none;
    /* 去掉下边框 */
    border-radius: 2px;
    font-size: 18px;

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 0 10px;
      border-bottom: 2px solid black;
    }

    div {
      height: 47.3px;
      line-height: 47.3px;
      text-align: center;
    }
  }

  .make_btn {
    font-size: 15px;
    color: #fff;
    text-align: center;
    font-style: normal;
    height: 47.7px;
    line-height: 47.7px;
    letter-spacing: 0.03em;
    background-color: rgb(247, 134, 51);
    width: 100%;
    cursor: default;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 1em;
    opacity: 1;
    box-sizing: border-box;
    // pointer-events: none;
  }

  .make_btn_active {
    background-color: rgb(247, 134, 51);
    pointer-events: auto !important;
  }

  .make_btn_disabled {
    background-color: #8b8b8b !important;
    pointer-events: none;
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  :deep(.el-calendar__header) {
    padding: 10px 0;
    justify-content: space-around;
  }

  .weight {
    font-weight: 700;
  }

  .color_expired {
    background-color: #f5f5f5 !important;
    color: #cacaca !important;
  }

  .color_bai {
    background-color: #ffffff !important;
  }

  .color_appt {
    background: #10bd8e;
    color: #000000;
  }
  .borderActive {
    border: 2px solid #ae6ad9fa !important;
    box-sizing: border-box !important;
  }

  .color_appt_none {
    background-color: #8b8b8b;
    color: #000000;
  }

  .color_draf {
    background-color: #bbd2e9;
  }

  .timeTable {
    border-top: 2px solid black;
    /* 上边框 */
    border-right: 2px solid black;
    /* 右边框 */
    border-left: 2px solid black;
    /* 左边框 */
    border-bottom: none;
    /* 去掉下边框 */
    border-radius: 2px;
    font-size: 18px;

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 0 10px;
      border-bottom: 1px solid black;
    }

    div {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }

  .make_btn {
    font-size: 17px;
    color: #fff;
    text-align: center;
    font-style: normal;
    line-height: 47.4px;
    letter-spacing: 0.03em;
    background-color: rgb(247, 134, 51);
    width: 100%;
    cursor: default;
    font-weight: 700;
    margin-top: 15px;
    padding: 0 1em;
    opacity: 1;
    box-sizing: border-box;
    // pointer-events: none;
  }

  .make_btn_active {
    background-color: rgb(247, 134, 51) !important;
    pointer-events: auto !important;
  }

  .make_btn_disabled {
    background-color: #8b8b8b !important;
    pointer-events: none;
  }

  .calender-title {
    font-size: 14px;
    color: #0099a8;
    font-style: normal;
    text-align: left;
    line-height: 36px;
    letter-spacing: 0.03em;
    font-weight: 700;
    // float: left;
  }

  .calender-title1 {
    font-size: 13px;
    color: #333;
    font-style: normal;
    text-align: left;
    line-height: 34px;
    letter-spacing: 0.03em;
    font-weight: 400;
    clear: both;
    padding-left: 20px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .weight {
    font-weight: 600;
  }

  .Calendar-div {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
  }

  .gou {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .is-disabled {
    pointer-events: none;
    color: #ccc;
  }

  .is-color-red {
    background-color: rgb(255, 192, 192) !important;
    color: #ccc;
  }

  .is-color-blue {
    background-color: rgb(173, 216, 230) !important;
    color: #ccc;
  }

  .is-color-wir {
    background-color: #ffffff !important;
    color: #ccc;
  }

  .is-color-yellow {
    background-color: rgb(255, 255, 192) !important;
    color: #ccc;
  }

  .is-color-gray {
    background-color: rgb(136, 136, 136) !important;
    color: #ccc;
  }

  :deep(.el-calendar-day) {
    height: 40px !important;
  }

  :deep(.el-calendar-day) {
    padding: 0 !important;
  }

  :deep(.video-js) {
    object-fit: cover;
  }

  :deep(.el-drawer__header) {
    display: none;
  }

  /* 修改poster图片大小 */
  video::poster {
    width: 100% !important;
    height: 100% !important;
  }

  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }

  :deep(.btt) {
    background-color: rgba(0, 0, 0, 0.65);
  }

  :deep(.vjs-poster) {
    height: 100vh;
    background-size: cover !important;
  }

  .pc {
    display: none;
  }
}
</style>

<template>
  <div>
    <div class="container">
      <div class="calender-title1">
        Choose a phone appointment below:
        <span class="weight" style="color: red">{{ timeSlotList.length || 0 }}</span>
      </div>
      <!-- 日历 -->
      <el-calendar v-loading="isLoading">
        <template #date-cell="{ data }">
          <div>
            <template v-for="item in calendarData">
              <template v-if="item.dateCalendar.substring(0, 10) === data.day">
                <div
                  @click="tapSingleday(item)"
                  :key="item.dateCalendar"
                  :class="getClass(item)"
                >
                  {{ data.day.slice(-2) }}
                </div>
              </template>
            </template>
          </div>
        </template>
      </el-calendar>

      <div style="padding: 20px">
        <!-- 时间段 -->
        <div class="flex" v-for="(item, index) in timetable" :key="item.id">
          <span><span class="weight" v-if="index == 0"> Hours:</span></span>
          <span
            v-if="timeSlotList.length>0"
            >{{ convertToAMPM(item.timeStart) }} - {{ convertToAMPM(item.timeEnd) }}</span
          >
        </div>
        <!-- 表格 每小时时间 -->
        <div>
          <div class="timeTable">
            <div class="header">
              <span> </span>
              <span>
                {{ reverseDate(timeSlotListTitle) || "Unselected date" }} -
                {{ getDayOfWeek(timeSlotListTitle) || "" }}
              </span>
              <span> </span>
            </div>

            <div
              :style="{ 'pointer-events': styleTimeBox_disable ? 'none' : 'auto' }"
              v-for="item in timeSlotList"
              :key="item.id"
              class="header"
            >
              <span style="width: 30px"></span>
              <span>
                {{ convertToAMPM(item.timeStart) }} -
                {{ convertToAMPM(item.timeEnd) }}
              </span>
              <div>
                <img
                  @click="cancelAppointment(item)"
                  v-if="item.clientId"
                  style="width: 30px; height: 30px; margin-top: 9px"
                  src="../../assets/shixing.png"
                  alt=""
                />
                <img
                  @click="joinAppointment(item)"
                  v-else
                  style="width: 30px; height: 30px; margin-top: 9px"
                  src="../../assets/kongxing.png"
                  alt=""
                />
              </div>
              <!-- <div style="width: 30px" v-else></div> -->
            </div>
          </div>
          <div v-if="timeSlotList.length > 0">
            <el-button
              :loading="makeBtnLoading"
              class="make_btn"
              :style="{
                background: appointment_disable ? '#8b8b8b' : '',
                height: '47.7px',
                lineHeight: '47.7px',
              }"
              :disabled="appointment_disable"
              @click="subscribeOneHour"
            >
              MAKE APPOINTMENT</el-button
            >
          </div>
          <div v-else class="make_btn make_btn_disabled">NO APPOINTMENTS AVAILABLE</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, onBeforeUnmount, computed, reactive, ref, onBeforeMount } from "vue";
import { getOnlinePeriod, setAppointment } from "@/api/detailPage.js";
import { getToken, getType } from "@/utils/auth";
import bus from "vue3-eventbus";
import {
  convertToAMPM,
  nowDay,
  gtmTransform,
  addOneMonthAndSetToFirstDay,
  reverseDate,
  getDayOfWeek,
  selectDay
} from "@/components/timeTransform/index.js";
import { debounce } from "lodash";

const currentMonth = ref(new Date().getMonth() + 1);
//预约按钮loading
const makeBtnLoading = ref(false);
//拿当天 日期 年月日
const toDay = computed(() => {
  return nowDay();
});
//时区偏移量
const tZoneOffset = computed(() => {
  let time = new Date().getTimezoneOffset();
  return 0 - time / 60;
});
//当前时间 new Date() GMT 格式
const nowTime = ref(new Date());
//表格
const timeSlotList = ref([]);
const timeSlotListTitle = ref("");
const isLoading = ref(false)

//以当前天数为标准的头尾日期
const endOfCalendar = ref([]);
//时间表
const timetable = ref([]);
//是否可预约
const calendarData = ref([]);
//预约按钮状态
const appointment_disable = ref(true);
//表格点击是否禁用状态
const styleTimeBox_disable = ref(false);

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  consultantId: {
    type: Number,
    required: true,
  },
});

// ------ 函数部分 ------

//获取 日历组件的样式动态
const getClass = (item) => {
  const classes = ["Calendar-div"];
  if (item.dateCalendar == timeSlotListTitle.value) {
    classes.push("borderActive");
  }

  if (item.apptStatus === "APPT_NONE") {
    classes.push("color_appt_none");
  } else if (item.apptStatus === "APPT") {
    classes.push("color_appt");
  }
  //borderActive

  if (item.dateCalendar.substring(0, 11) < gtmTransform(new Date())) {
    classes.push("color_expired");
  }

  return classes;
};

//点击日历单天
const tapSingleday = (item) => {
  console.log("我来了我来了");
  console.log(item, "item");

  let str = new Date(item.dateCalendar);
  nowTime.value = str;
  getCalendarDates(str);

  styleTimeBox_disable.value = false;
  appointment_disable.value = true;
  timeSlotListTitle.value = item.dateCalendar.slice(0, 10);
  timeSlotList.value = []; // 这个是拆成每一个小时展示
  timetable.value = []; //这个是时段展示
  console.log(item, "item");
  console.log(nowDay(), "toDay.value");

  if (item.scheduleList) {
    timetable.value = item.scheduleList;
    if (item.scheduleList.length > 0) {
      // 假设默认情况下appointment_disable.value应该是false，除非找到匹配的apptItem
      appointment_disable.value = true; // 重置为默认值

      item.scheduleList.forEach((scheduleItem) => {
        if (scheduleItem.apptList.length > 0) {
          scheduleItem.apptList.forEach((apptItem) => {
            if (apptItem.clientId == null || apptItem.clientId == props.userId) {
              timeSlotList.value.push(apptItem);

              // 如果找到clientId与props.userId匹配的apptItem，则设置appointment_disable.value为true
              if (apptItem.clientId == props.userId) {
                appointment_disable.value = true;
                styleTimeBox_disable.value = true;
              }
            }
          });
          console.log(timeSlotList.value, "TTTTTT");
          timeSlotList.value.sort((a, b) => {
            // 按开始时间排序
            if (a.timeStart < b.timeStart) return -1;
            if (a.timeStart > b.timeStart) return 1;
            // 如果开始时间相同，则按结束时间排序
            if (a.timeEnd < b.timeEnd) return -1;
            if (a.timeEnd > b.timeEnd) return 1;
            return 0;
          });
        }
      });
    }

    console.log(timetable.value, "timetable.value");
  }

  if (item.dateCalendar < nowDay()) {
    appointment_disable.value = true;
  }
  if (!getToken() || getType() == "1") {
    styleTimeBox_disable.value = false;
  }
  if (toDay.value > timeSlotListTitle.value) {
    styleTimeBox_disable.value = true;
  }


  // getOnlinePeriodList();

  const clickedMonth = Number(item.dateCalendar.substring(5, 7)) > 10 ?Number(item.dateCalendar.substring(5, 7)): Number(item.dateCalendar.substring(6, 7))
  console.log(clickedMonth,"clickedMonth")

  // 检查点击的月份是否为下个月份
  if (clickedMonth !== currentMonth.value) {
    // 更新当前月份
    currentMonth.value = clickedMonth;
    
    // 执行函数
    getOnlinePeriodList();
    console.log(nowTime.value,"now1")
  }
};

//点击预约
const subscribeOne = () => {
  if (!getToken() || getType() == "1") {
    ElMessage({
      message: "Please login first and then make an appointment!",
      type: "warning",
    });
    bus.emit("joinOnLogin", true);
    return;
  }
  let some = timeSlotList.value.some((x) => x.clientId);
  if (!some) {
    return ElMessage({
      message: "Please select the time period first.",
      type: "warning",
    });
  }
  let arr = timeSlotList.value.filter((x) => x.clientId);

  // console.log("预约", timeSlotList.value, "timeSlotList.value");
  ElMessageBox.confirm(
    `<p>Would you like to make an appointment from
    <span style="color: #10bd8e;">${arr[0].date.slice(0, 10)}</span>
    <span style="color: red;">${convertToAMPM(arr[0].timeStart)} - </span>
    <span style="color: red;">${convertToAMPM(arr[0].timeEnd)}</span>?
  </p>`,
    "Reservation prompt!",
    {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      dangerouslyUseHTMLString: true,
    }
  )
    .then(async () => {
      makeBtnLoading.value = true;
      styleTimeBox_disable.value = true;
      let data = arr[0];
      console.log("data", data);

      let res = await setAppointment(data);
      if (res.code == "200") {
        appointment_disable.value = true;
        makeBtnLoading.value = false;
        styleTimeBox_disable.value = true;
        toDayItem(true);

        ElMessage({
          message: "Congratulations on your appointment.",
          type: "success",
          duration: 5000,
          showClose: true,
        });
      } else if (res.code == "500") {
        ElMessage({
          message: `${res.msg}`,
          type: "error",
        });
      } else {
        ElMessage({
          message: "Failed to connect to the server. Please try again later.",
          type: "error",
        });
      }
      makeBtnLoading.value = false;
      styleTimeBox_disable.value = res.code !== "200";
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Cancel an appointment.",
      });
    });
};
//节流 点击预约
const subscribeOneHour = debounce(subscribeOne, 500);

//加入预约
const joinAppointment = (item) => {
  console.log(timeSlotList.value, "list");
  timeSlotList.value = timeSlotList.value.map((item) => {
    return {
      ...item,
      clientId: null,
    };
  });
  console.log(item, "item");
  let index = timeSlotList.value.findIndex((info) => info.timeStart === item.timeStart);
  console.log(index, "index");
  if (index !== -1 && props.userId) {
    timeSlotList.value[index].clientId = props.userId;
    appointment_disable.value = false;
  } else {
    timeSlotList.value[index].clientId = "N";
    appointment_disable.value = false;
  }
};
//取消预约
const cancelAppointment = (item) => {
  console.log(timeSlotList.value, "list");
  timeSlotList.value = timeSlotList.value.map((item) => {
    return {
      ...item,
      clientId: null,
    };
  });
  appointment_disable.value = true;
};

//查找日日历头尾 以当前天数为标准 endOfCalendar 数组用于存放 日历的头尾日期 参数today 是当前天
const getCalendarDates = (today) => {
  //获取当前日期
  // let today = new Date();
  console.log(today, "zzzz");

  //获取当月的第一天
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  //获取当月的最后一天
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  //日历通常会从周日开始，所以需要获取这个月的第一天是星期几，然后再算出日历的开始日期
  //如果第一天是周二（getDay() === 2），那日历应该从前一周的周日开始。
  let calendarStart = new Date(
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
  );

  //同理，我们也需要计算出日历的结束日期
  let calendarEnd = new Date(
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
  );

  //转化日期为 YYYY-MM-DD 格式
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  console.log([formatDate(calendarStart), formatDate(calendarEnd)], "zzzz");
  // 装日历 头尾的数组
  return (endOfCalendar.value = [formatDate(calendarStart), formatDate(calendarEnd)]);
};

//初始化当天的可预约时间 参数arr 的值一般是 endOfCalendar
const toDayItem = async (getDate = false) => {
  let data = {
    dateStart: endOfCalendar.value[0],
    dateEnd: endOfCalendar.value[1],
    expertId: props.consultantId,
    published: true,
    timeZone: tZoneOffset.value,
    clientId:
      JSON.parse(window.localStorage.getItem("userInfo")) && getType() !== "1"
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : null,
  };
  let res = await getOnlinePeriod(data);
  if (res.code == "200") {
    if (Array.isArray(res.data) && res.data) {
      console.log(res.data, "res.data");
      calendarData.value = res.data;
      if (calendarData.value.length > 0) {
        console.log(calendarData.value, "today.value");
        console.log(toDay.value, "today");
        let arr = [];
        if (getDate) {
          arr = calendarData.value.filter(
            (item) => item.dateCalendar === timeSlotListTitle.value
          );
        } else {
          arr = calendarData.value.filter((item) => item.dateCalendar == toDay.value);
        }

        tapSingleday(arr[0]);
      }
    }
  }
};

//获取日历的数据
const getOnlinePeriodList = async () => {
  isLoading.value =true
  let data = {
    dateStart: endOfCalendar.value[0],
    dateEnd: endOfCalendar.value[1],
    expertId: props.consultantId,
    published: true,
    timeZone: tZoneOffset.value,
    clientId:
      JSON.parse(window.localStorage.getItem("userInfo")) && getType() !== "1"
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : null,
  };
  let res = await getOnlinePeriod(data);
  if (res.code == "200") {
    if (Array.isArray(res.data) && res.data) {
      console.log(res.data, "res.data");
      calendarData.value = res.data;
      isLoading.value = false
      
      console.log(selectDay(nowTime.value),"现在时间")
    }
  }
};

// 定义处理函数
const handlePrevClick = () => {
  currentMonth.value = null
  console.log(selectDay(nowTime.value),"上一个前")
  // console.log("上一个");
  nowTime.value = addOneMonthAndSetToFirstDay(nowTime.value, "jj");
  console.log(selectDay(nowTime.value),"上一个后")
  getCalendarDates(nowTime.value);
  console.log(endOfCalendar.value, "endOfCalendar.value");
  getOnlinePeriodList();
};

const handleNowClick = () => {
  currentMonth.value = null
  console.log("当前");
  nowTime.value = new Date();
  getCalendarDates(nowTime.value);
  // getOnlinePeriodList();
  toDayItem();
};

const handleNextClick = () => {
  currentMonth.value = null
  console.log("下一个");
  nowTime.value = addOneMonthAndSetToFirstDay(nowTime.value, "add");
  getCalendarDates(nowTime.value);
  getOnlinePeriodList();
};

onMounted(() => {
  //查找日历头尾 默认当天为基准
  getCalendarDates(nowTime.value);
  setTimeout(() => {
    // 初始化化当天可预约的日期和时间
    // 这里假设getOnlinePeriodList和toDayItem不依赖于DOM
    getOnlinePeriodList();
    toDayItem();

    // 添加事件监听器
    const prevBtn = document.querySelector(
      ".el-calendar__button-group .el-button-group>button:nth-child(1)"
    );
    const nowBtn = document.querySelector(
      ".el-calendar__button-group .el-button-group>button:nth-child(2)"
    );
    const nextBtn = document.querySelector(
      ".el-calendar__button-group .el-button-group>button:nth-child(3)"
    );

    prevBtn?.addEventListener("click", handlePrevClick);
    nowBtn?.addEventListener("click", handleNowClick);
    nextBtn?.addEventListener("click", handleNextClick);
  }, 500);
});
// 在组件卸载前移除事件监听器
onBeforeUnmount(() => {
  const prevBtn = document.querySelector(
    ".el-calendar__button-group .el-button-group>button:nth-child(1)"
  );
  const nowBtn = document.querySelector(
    ".el-calendar__button-group .el-button-group>button:nth-child(2)"
  );
  const nextBtn = document.querySelector(
    ".el-calendar__button-group .el-button-group>button:nth-child(3)"
  );

  prevBtn?.removeEventListener("click", handlePrevClick);
  nowBtn?.removeEventListener("click", handleNowClick);
  nextBtn?.removeEventListener("click", handleNextClick);
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 3px;
  /* 滚动条宽度 */
}

.p {
  line-height: 25px;
  font-size: 18px;
  margin-top: 5px;
  color: #000000;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

:deep(.el-rate__text) {
  // color: #fff;
}

:deep(.el-rate) {
  height: 18px;
}

:deep(.el-rate .el-rate__icon) {
  margin: 0;
}

.filtergly {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray !important;
}

.upImages {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div:nth-child(6n + 0) {
    margin-right: 0 !important;
  }
}

.middle {
  width: 100%;
  background: #deeff3;

  .content {
    width: 1250px;
    // height: 1000px;
    margin: auto;

    .mid-video {
      width: 100%;
      display: flex;
      padding-top: 30px;

      .video {
        width: 850px;
        height: 517px;

        .video-top {
          width: 100%;
          height: 479px;
          background-color: black;

          video {
            min-width: 100%;
            // min-height: 100%; //不会因视频尺寸造成页面需要滚动
            // width: auto;
            // height: auto; //尺寸保持原视频大小
            z-index: -100; //z轴定位，小于0即可
            filter: grayscale(20%); //添加灰度蒙版，如果设定为100%则视频显示为黑白
          }
        }

        .video-player {
          background-color: red;
          // z-index: 100;
        }

        .video-gif {
          width: 100%;
          display: flex;
          border-radius: 5px;

          ul {
            width: 435px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #221c1c;

            .gif-send {
              font-size: 12px;
              padding: 0 18px;
              text-align: center;

              .send {
                color: #f3f5f8;
                font-size: 12px;
                margin: auto;
              }
            }

            .moji-item {
              .img-wrap:hover {
                background: #fff;
                box-shadow: 0 0 14px 2px #ffcc00;
              }

              .img-wrap {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img:hover {
                  box-shadow: 0 0 10px 0 #ffcc00;
                  background-color: #fff;
                }
              }
            }

            li {
              list-style: none;
              width: 23px;
              height: 25px;
              padding-top: 5px;
              cursor: pointer;
              margin-right: 10px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 20px;
              }
            }
          }

          .gif-icon {
            cursor: pointer;
          }

          .gif-messg {
            width: 220px;
            height: 37px;
            background: #ffe400;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0px 3px;
          }

          .buy-thing {
            width: 180px;
            height: 37px;
            background: #222222;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0px 5px;
          }
        }
      }

      .chat-all {
        width: 400px;
        height: 517px;
        background: #02758a;
        position: relative;

        .zhuanHuan {
          position: absolute;
          right: 10px;
          top: 3px;
          z-index: 999;
        }

        .chat-top {
          position: relative;
          width: 400px;
          height: 517px;
          overflow: hidden;
          background-color: #deeff3;

          // img {
          //     width: 350px;
          //     height: 170px;
          // }
          .calendaBox {
            overflow-y: auto;
            height: 450px;
          }

          .chat-close {
            position: absolute;
            top: 8px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .close {
              background-color: #888282;
              padding: 4px;
              margin-right: 3px;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }

        .chat-messgae {
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.3);
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;

          p {
            margin-left: 6px;
          }
        }

        .chat-mess {
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;

          p {
            margin-left: 6px;
          }
        }

        .messages {
          width: 100%;
          height: 450px;
          background-color: rgba(0, 0, 0, 0.3);
          position: relative;

          .showTipMsg {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            color: #0fd6cc;
            font-size: 16px;
          }

          ul {
            // padding-top: 174px;
            height: 450px;
            overflow-y: auto;

            li {
              list-style: none;
              color: #ffffff;
              font-size: 12px;
              padding: 5px;
            }
          }
        }

        .talk {
          width: 100%;
          height: 37px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 12px;
          line-height: 37px;
          cursor: pointer;

          p {
            margin-left: 4px;
          }
        }

        .send-text {
          height: 37px;

          :deep(.el-input__wrapper) {
            border-radius: 0;
            height: 37px;
          }

          :deep(.el-button) {
            border-radius: 0;
            height: 37px;
          }
        }
      }
    }

    .information {
      margin-top: 32px;
      width: 100%;
      display: flex;

      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 40px;
          height: 100%;
          object-fit: cover;
          // border-radius: 40px;
        }
      }

      .infor-name {
        width: 200px;
        margin-left: 16px;
        // color: #ffffff;
        p {
          width: 500px;
          // background-color:red;
        }
      }

      .reviews {
        width: 80%;
        line-height: 45px;
        text-align: right;
      }
    }

    .infor-text {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .text {
        width: 40%;
        font-size: 12px;
        color: #ccb8dc;
      }

      .classification {
        font-size: 18px;
        // color: #ffffff;
        width: 30%;

        .cation {
          border-left: solid 1px;
          padding-left: 30px;
          box-sizing: border-box;
          // color: #fff !important;
        }
      }

      .classification2 {
        font-size: 12px;
        // color: #ffffff;
        display: flex;

        .cation {
          border-left: solid 1px;
          padding-left: 30px;
          opacity: 0.4;
          margin-left: 100px;
        }
      }
    }

    .illustrate {
      // color: #ffffff;
      font-size: 22px;

      p {
        font-size: 18px;
        line-height: 25px;
        color: #ccb8dc;
      }
    }

    .selections {
      .demo-tabs > .el-tabs__content {
        padding: 32px;
        color: #f3f5f8;
        font-size: 32px;
        font-weight: 600;
      }

      :deep(.el-tabs__nav-wrap is-top.el-tabs__nav-wrap::after) {
        background-color: transparent;
      }
    }

    .recommend {
      margin-top: 40px;

      ul {
        display: flex;
        // justify-content: space-around;

        li {
          width: 300px;
          height: 218px;
          list-style: none;
          margin-right: 20px;
          position: relative;
          cursor: pointer;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          .recomd-icon {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;
          }

          .recomd-peple {
            position: absolute;
            color: #ffffff;
            background-color: #0fd6cc;
            top: 10px;
            left: 8px;
            font-size: 12px;
            padding: 3px;
            border-radius: 5px;
          }
        }
      }
    }

    .main-serve {
      // height: 650px;
      background: #fff;
      // padding: 5px;
      border-radius: 20px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 10px 20px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      margin-bottom: 50px;

      h3 {
        padding: 10px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        // border: 2px solid #eee;
      }

      p {
        padding: 5px 10px;
        width: 100%;
        font-size: 18px;
        // padding: 5px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 20px;
      }

      div {
        overflow: hidden;
        white-space: normal;
        word-wrap: break-word;
      }
    }

    .tailLanguage {
      margin-top: 20px;
      padding-bottom: 50px;

      h3 {
        margin-bottom: 10px;
        padding-left: 5px;
        font-size: 22px;
      }

      h5 {
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
      }

      .changge {
        display: flex;
        justify-content: space-between;
        color: #ccb8dc;
        font-size: 12px;

        p {
          line-height: 20px;
          margin-right: 6px;
        }
      }
    }
  }
}

.comment {
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 400px;
  height: 340px;
  background-color: #3a7e8a;
  padding: 10px;

  .com-head {
    line-height: 40px;
    color: #fff;
    text-align: center;
  }

  .com-infor {
    margin: 15px 60px;
    display: flex;

    .infor-left {
      margin-bottom: 20px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      p {
        color: #fff;
      }
    }

    .infor-right {
      margin: 0 25px;
      margin-top: 15px;
    }
  }

  .com-submit {
    width: 100px;
    padding: 10px 15px;
    background-color: #ffcc00;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }

  .chaIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
</style>
